import React from 'react'
import Table from "../util/table/Table.jsx";
import ActionIcon from "./ActionIcon.jsx";
import LocationIcon from "./LocationIcon.jsx";
import dayjs from "dayjs";
import useLazyListLoader from "../util/lasy-loader/useLazyListLoader.js";
import {HorizontalLoadWrapper} from "../util/loaders/LoaderHorizontal.jsx";
import {ACTIONS, isAccident} from "./_helpers.js";
import Counter from "../util/Counter.jsx";
import LiquidSpotIcon from "mdi-react/LiquidSpotIcon.js";
import {useDogData} from "./_use-dawg-data.js";
import TimeAgo from "./TimeAgo.jsx";

export default () => {

    const {
        dates,
        processedLog,
        medians,
        isLoading,
        medianDailyPees,
        medianDailyPoops,
        medianDailyFoods,
    } = useDogData()

    const {visibleList, ListLoaderComponent} = useLazyListLoader(dates, 4, 1)

    return (
        <div className='sushi-log children-slide-right'>
            <HorizontalLoadWrapper isLoading={isLoading}>
                <Table className='rounded'>
                    <tbody className=''>
                    {visibleList.map(date => {
                        const {entries, poops, pees, foods, accidents} = processedLog[date]
                        return (
                            <React.Fragment key={date}>
                                <tr>
                                    <td colSpan={5} className='date-header'>
                                        <div className='wrapper'>
                                            <div className='date'>
                                                <div>
                                                    {dayjs(date).format("dddd")}
                                                </div>
                                                <div className='weak'>
                                                    {date}
                                                </div>
                                            </div>
                                            <div className='stats'>
                                                {accidents > 0 &&
                                                <div className='stat'>
                                                    <LiquidSpotIcon className='is-negative'/>
                                                    <Counter amount={accidents} decimals={0}/>
                                                </div>
                                                }
                                                <div className={`stat ${getMedianClassName(poops, medianDailyPoops, .15)}`}>
                                                    <ActionIcon action={ACTIONS.POOP} className='std-icon'/>
                                                    <Counter amount={poops} decimals={0}/>
                                                </div>
                                                <div className={`stat ${getMedianClassName(pees, medianDailyPees, .15)}`}>
                                                    <ActionIcon action={ACTIONS.PEE} className='std-icon'/>
                                                    <Counter amount={pees} decimals={0}/>
                                                </div>
                                                <div className={`stat ${getMedianClassName(foods, medianDailyFoods, 0)}`}>
                                                    <ActionIcon action={ACTIONS.FOOD} className='std-icon'/>
                                                    <Counter amount={foods} decimals={0}/>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                {entries.map(entry =>
                                    <LogEntry
                                        key={entry.id}
                                        isBadLocation={isAccident(entry)}
                                        medians={medians}
                                        {...entry}
                                    />
                                )}
                            </React.Fragment>
                        )
                    })}
                    </tbody>
                </Table>
                <ListLoaderComponent/>
            </HorizontalLoadWrapper>
        </div>
    )
}

const LogEntry = ({action, location, datetime, comment, isBadLocation, timeDiff, medians, user_id}) => {
    const timeDiffClassName = getMedianClassName(timeDiff, medians[action])
    return (
        <tr>
            <td className='hide-portrait'>
                <div className={`flex-vertical-center ${isBadLocation ? 'is-negative' : ''}`}>
                    <ActionIcon action={action} className='std-icon'/>
                    {action}
                </div>
            </td>
            <td className='hide-portrait'>
                <div className={`flex-vertical-center ${isBadLocation ? 'is-negative' : ''}`}>
                    <LocationIcon location={location} className='std-icon'/>
                    {location}
                </div>
            </td>
            <td className={`hide-portrait ${isBadLocation ? 'is-negative' : ''}`}>
                <div className='is-weak '>
                    {dayjs(datetime).format("HH:mm")}
                </div>
            </td>
            <td className='hide-portrait time-diff'>
                {timeDiff &&
                <div className={timeDiffClassName}>
                    <TimeAgo ms={timeDiff} />
                </div>
                }
            </td>
            <td className={'hide-desktop mobile-log-entry'}>
                <div className={`flex-vertical-center`}>
                    <div className={`flex-vertical-center right ${isBadLocation ? 'is-negative' : ''}`}>
                        <div className='flex-vertical-center'>
                            <ActionIcon action={action} className='std-icon'/>
                            {action}
                        </div>
                        <div className='flex-vertical-center secondary'>
                            <LocationIcon location={location} className='std-icon'/>
                            {location}
                        </div>
                    </div>
                    <div className='flex-vertical-center left'>
                        <div className={`time-diff secondary ${timeDiffClassName}`}>
                            {timeDiff &&
                            <TimeAgo ms={timeDiff} />
                            }
                        </div>
                        <div>
                            {dayjs(datetime).format("HH:mm")}
                        </div>
                    </div>
                </div>
                {!!comment &&
                <div className='comment'>
                    {comment}
                </div>
                }
            </td>
            <td className='hide-portrait comment'>
                {comment}
            </td>
        </tr>

    )
}

const getMedianClassName = (value, median, leeway = .25) => {
    const delta = Math.abs(median - value)
    if(value === 0){
        return 'is-zero'
    }
    if(!value || delta < median * leeway || value === median){
        return ''
    }
    return median <= value ? 'is-positive' : 'is-negative'
}