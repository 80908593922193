import {useDispatch, useSelector} from "react-redux";
import {closePopup} from "../../../ducks/popup-duck.js";
import {useState} from "react";
import Button from "../../util/Button.jsx";
import './AddRateDialog.scss'
import AddIcon from "mdi-react/AddIcon.js";
import dayjs from "dayjs";
import {getCurrencyRateForDate, postRateApi} from "../../../api/statusApi.js";
import {useChillEffect} from "../../../util/mounting-hooks.js";
import {getUsdRate} from "../../../ducks/assets-duck.js";

export default ({currentRate, assetName, assetId, date = dayjs().format('YYYY-MM-DD')}) => {

    const dispatch = useDispatch()
    const currentUsdRate = useSelector(getUsdRate)
    const [isPosting, setIsPosting] = useState(false)
    const [dateInput, setInputDate] = useState(date)
    const [rate, setRate] = useState(currentRate)
    const [usdRate, setUsdRate] = useState(currentRate / currentUsdRate)
    const [usdRateForDate, setUsdRateForDate] = useState()

    const usdExchangeRate = usdRateForDate || currentUsdRate

    const postRate = async () => {
        setIsPosting(true)
        await postRateApi({
            assetId,
            date: dateInput,
            rate,
            usdRate,
        })
        dispatch(closePopup())
    }

    useChillEffect(() => {
        getCurrencyRateForDate('USD', dateInput).then(rate => {
            setUsdRateForDate(rate === 1 ? currentUsdRate : rate)
        })
    }, [dateInput])

    const onChangeRateInput = e => {
        setRate(e.target.value)
        setUsdRate(e.target.value / usdExchangeRate)
    }

    const onChangeRateUsdInput = e => {
        setUsdRate(e.target.value)
        setRate(e.target.value * usdExchangeRate)
    }

    return (
        <div className='add-rate-dialog'>
            <h2>
                Set rate for <span className='asset-name'>{assetName}</span>
            </h2>
            <div className='input-div'>
                <input
                    type='date'
                    value={dateInput}
                    onChange={e => setInputDate(e.target.value)}
                />
            </div>
            <div className='input-div'>
                <input
                    type='number'
                    value={rate}
                    placeholder={'new rate'}
                    onChange={onChangeRateInput}
                />
                <div className='label'>
                    kr
                </div>
            </div>
            <div className='input-div'>
                <input
                    type='number'
                    value={usdRate}
                    placeholder={'new rate usd'}
                    onChange={onChangeRateUsdInput}
                />
                <div className='label'>
                    $
                </div>
            </div>
            <div className='button-container'>
                <Button
                    onClick={postRate}
                    disabled={isPosting}
                    icon={<AddIcon/>}
                >
                    Set rate
                </Button>
            </div>
        </div>
    )
}