export const DECIMAL_FORMAT = {
    FLEX: -1,
    FLEX_DEC: -2,
    FLEX_DEC_NO_TRAIL: -6,
    FLEX_INT: -3,
    FLEX_ONE_DEC_ONLY: -4,
    FLEX_PERCENTAGE: -5,
}

export function numberFormat(number, decimals, removeTrailingZero = false) {
    if ([DECIMAL_FORMAT.FLEX_INT, DECIMAL_FORMAT.FLEX].includes(decimals)) {
        let suffix = ""
        if (Math.abs(number) >= 950_000_000) {
            suffix = "B"
            number = (Math.round(number / 10_000_000) / 100)
        } else if (Math.abs(number) >= 950_000) {
            suffix = "M"
            number = (Math.round(number / 10_000) / 100)
        } else if (Math.abs(number) >= 1_000) {
            suffix = "k"
            number = (Math.round(number / 10) / 100)
        } else if (Math.abs(number) >= 100) {
            number = Math.round(number)
        } else if (decimals === DECIMAL_FORMAT.FLEX_INT) {
            number = Math.round(number)
        }
        if (number % 1 === 0 || number >= 100 || decimals === DECIMAL_FORMAT.FLEX_INT || number === 0) {
            decimals = 0
        } else if (number >= 10) {
            decimals = 1
        } else {
            decimals = 2
        }
        return number_format(number, decimals, ',', ' ', true) + suffix
    }
    if ([DECIMAL_FORMAT.FLEX_ONE_DEC_ONLY].includes(decimals)) {
        if (Math.abs(number) >= 100_000_000) {
            number = (Math.round(number / 100_000_000) / 10) + 'B'
        } else if (Math.abs(number) >= 1_000_000) {
            number = (Math.round(number / 100_000) / 10) + 'M'
        } else if (Math.abs(number) >= 10_000) {
            number = (Math.round(number / 100) / 10) + 'k'
        } else if (Math.abs(number) >= 10) {
            number = Math.round(number)
        } else {
            number = Math.round(number * 10) / 10
        }
        return number
    }
    if ([DECIMAL_FORMAT.FLEX_DEC, DECIMAL_FORMAT.FLEX_DEC_NO_TRAIL].includes(decimals)) {
        if (decimals === DECIMAL_FORMAT.FLEX_DEC_NO_TRAIL) {
            removeTrailingZero = true
        }
        if (number === 0) {
            decimals = 0
        } else if (Math.abs(number) < 10) {
            decimals = 4
        } else if (Math.abs(number) < 1000) {
            decimals = 2
        } else {
            decimals = 0
        }
    }
    if (decimals === DECIMAL_FORMAT.FLEX_PERCENTAGE) {
        decimals = 2
        if (number >= 100) {
            decimals = 0
        } else if (number >= 10) {
            decimals = 1
        }
        removeTrailingZero = true
    }
    return number_format(number, decimals, ',', ' ', removeTrailingZero)
}

export const number_format = (number, decimals, decPoint, thousandsSep, removeTrailingZero = false) => {
    number = (number + '')
        .replace(/[^0-9+\-Ee.]/g, '')
    const n = !isFinite(+number) ? 0 : +number
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
    const dec = (typeof decPoint === 'undefined') ? '.' : decPoint
    let s = ''
    const toFixedFix = function (n, prec) {
        const k = Math.pow(10, prec)
        return '' + (Math.round(n * k) / k)
            .toFixed(prec)
    }
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
        .split('.')
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '')
        .length < prec) {
        s[1] = s[1] || ''
        s[1] += new Array(prec - s[1].length + 1)
            .join('0')
    }
    if (removeTrailingZero && s[1]) {
        s[1] = s[1]?.replace(/0*$/, '')
    }
    if (!s[1]) return s[0]
    return s.join(dec)
}

export function round(number, decimalPoints) {
    return Math.round(number * (10 ** decimalPoints)) / (10 ** decimalPoints)
}

export const zeroPad = (num, places) => String(num).padStart(places, '0')

export const median = (arr = []) => {
    const arraySorted = [...arr].sort((a, b) => a - b)
    const middleIndex = Math.floor(arraySorted.length / 2)

    if (arraySorted.length % 2 === 0) {
        return (arraySorted[middleIndex - 1] + arraySorted[middleIndex]) / 2
    } else {
        return arraySorted[middleIndex]
    }
}

export const sumProps = (arrayOfObjects, propsToSum = [], isAbsoluteValues = false) => {
    const initialObject = propsToSum.reduce((acc, cur) => ({...acc, [cur]: 0}), {})
    return arrayOfObjects.reduce((acc, cur) => {
        return propsToSum.reduce((acc2, propName) =>
                ({...acc2, [propName]: acc2[propName] + (isAbsoluteValues ? Math.abs(cur[propName]) : cur[propName])})
            , acc)
    }, initialObject)
}