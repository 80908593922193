import {getAssets} from "../../ducks/assets-duck.js";
import {getSubPathCache} from "../../ducks/user-duck.js";
import {useSelector} from "react-redux";
import {scrollToTop, useNavigation} from "../util/navigation/navigation.js";
import CompositionBar from "../util/compoisiton-bar/CompositionBar.jsx";
import {DECIMAL_FORMAT} from "../../util/numbers.js";
import {ASSET_IDS} from "../../util/asset-ids.js";

const PROPS = [
    {
        id: 'change',
        label: 'change',
        isColored: true,
        includeRelativeValue: false,
        filter: item => !!item.isMostRecentUpdate,
    },
    {
        id: 'changePct',
        label: 'change%',
        isColored: true,
        includeRelativeValue: false,
        filter: item => !!item.isMostRecentUpdate,
        absoluteValueDecimals: DECIMAL_FORMAT.FLEX_PERCENTAGE,
        absoluteValueSuffix: '%',
    },
]


export default () => {

    const assets = useSelector(getAssets)
    const subPathCache = useSelector(getSubPathCache)

    const {navigateTo} = useNavigation(scrollToTop)

    const items = assets.filter(asset => asset.id !== ASSET_IDS.DAI).map(({name, code, changePct, ...restOfProps}) => ({
        label: name,
        shortLabel: code,
        changePct: changePct * 100,
        ...restOfProps,
    }))

    const objectOnClick = id => {
        let APP_ROUTES;
        const route = subPathCache ? APP_ROUTES.history.replace('history', subPathCache) : APP_ROUTES.history
        navigateTo(route, {filterId: id})
    }

    return (
        <CompositionBar
            items={items}
            props={PROPS}
            objectOnClick={objectOnClick}
            label='asset-list'
            showSelectionControls={false}
            className='asset-list-composition-bar'
        />
    )
}