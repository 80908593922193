import './Subnav.scss'
import {NavLink} from "react-router-dom";
import {useNavigation} from "../util/navigation/navigation.js";
import CalendarIcon from "mdi-react/CalendarIcon.js";
import SwapHorizontalBoldIcon from "mdi-react/SwapHorizontalBoldIcon.js";
import GaugeIcon from "mdi-react/GaugeIcon.js";
import {APP_ROUTES} from "../../App.jsx";
import FormatListBulletedIcon from "mdi-react/FormatListBulletedIcon.js";
import {useSelector} from "react-redux";
import {getFilterPreset, getIsBreadcrumbsVisible} from "../../ducks/assets-duck.js";

export default () => {
    const {getRoute} = useNavigation()
    const filterPreset = useSelector(getFilterPreset)
    const isVisible = useSelector(getIsBreadcrumbsVisible)
    return isVisible && (
        <div className='dashboard-sub-nav'>
            <div className="links children-slide-down s10">
                <NavLink
                    to={getRoute(APP_ROUTES.filter, {filterId: filterPreset,})}
                    activeClassName="is-active"
                    exact={true}
                >
                    <FormatListBulletedIcon/>
                    <span className='text'>
                        Assets
                    </span>
                </NavLink>
                <NavLink
                    to={getRoute(APP_ROUTES.history)}
                    activeClassName="is-active"
                >
                    <CalendarIcon/>
                    <span className='text'>
                        History
                    </span>
                </NavLink>
                <NavLink
                    to={getRoute(APP_ROUTES.transactions)}
                    activeClassName="is-active"
                >
                    <SwapHorizontalBoldIcon/>
                    <span className='text'>
                        Transactions
                    </span>
                </NavLink>
                <NavLink
                    to={getRoute(APP_ROUTES.stats)}
                    activeClassName="is-active"
                >
                    <GaugeIcon/>
                    <span className='text'>
                        Stats
                    </span>
                </NavLink>
            </div>
        </div>
    )
}