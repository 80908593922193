export const CHART_PALETTE = {
    GOLDEN: {
        lineColor1: "#fd8701",
        lineColor2: "#ffdc01",
        fillColor1: "rgba(253,152,1,17.5%)",
        fillColor2: "rgba(253,152,1,0)",
        dashColor1: "rgba(255,196,1,75%)",
        dashColor2: "rgba(255,196,1,5%)",
    },
    POSITIVE: {
        lineColor1: "#00ff01",
        lineColor2: "#60ec7c",
        fillColor1: "rgba(96,236,124,17.5%)",
        fillColor2: "rgba(96,236,124,0)",
        dashColor1: "rgba(221,251,1,75%)",
        dashColor2: "rgba(221,251,1,5%)",
    },
    NEGATIVE: {
        lineColor1: "#7f4cb4",
        lineColor2: "#4e4357",
        fillColor1: "rgba(127,76,180,17.5%)",
        fillColor2: "rgba(127,76,180,0)",
        dashColor1: "rgba(150,94,178,75%)",
        dashColor2: "rgba(150,94,178,5%)",
    },
}