import './LiveChanges.scss'
import {numberFormat} from "../../util/numbers.js";
import AdditiveIcon from "../util/icons/AdditiveIcon.jsx";
import {useChanges} from "./_use-changes.js";
import {SEC} from "../../util/time-and-date.js";
import {useSelector} from "react-redux";
import {getIsLiveChangesVisible} from "../../ducks/assets-duck.js";

export default () => {
    const isLiveChangesVisible = useSelector(getIsLiveChangesVisible)
    const changeList = useChanges()
    return isLiveChangesVisible && (
        <div className='live-changes'>
            {[...changeList].reverse().map(change =>
                <Change
                    key={`${change.assetId}-${change.timestamp}`}
                    {...change}
                />
            )}
        </div>
    )
}

const Change = ({assetId, assetName, assetCode, diff, timestamp}) => {

    const formattedDiff = "" + numberFormat(Math.abs(diff), 0, true)
    const [digits, magnitude] = formattedDiff.match(/[a-z]+|[^a-z]+/gi)
    const timeDiff = Date.now() - timestamp

    return (
        <div className={`change flex-vertical-center ${diff > 0 ? 'is-positive' : 'is-negative'}`}>
            <AdditiveIcon
                amount={diff}
            />
            <div
                className='value'
            >
                <span className='digits'>{digits}</span>
                <span className='magnitude'>{magnitude}</span>
            </div>
            <div className='desc'>
                <div className='code'>
                    {assetCode}
                </div>
            </div>
            <div className='time-ago'>
                {Math.round(timeDiff / SEC)}<span className='suffix'>s</span>
            </div>
        </div>
    )
}