import {numberFormat, round} from "../../util/numbers.js";
import {getDirectionColorClassName} from "../../util/misc.js";
import {useSelector} from "react-redux";
import {getAssetsTotalGain, getAssetsTotalValue} from "../../ducks/assets-duck.js";
import {getChartFocus, getDiffTrackerValues, getIsDiffTrackerVisible} from "../../ducks/chart-duck.js";
import './DiffTracker.scss'
import {isToday} from "../../util/time-and-date.js";
import PlusBoldIcon from "mdi-react/PlusBoldIcon.js";
import MinusThickIcon from "mdi-react/MinusThickIcon.js";
import dayjs from "dayjs";
import CircleMediumIcon from "mdi-react/CircleMediumIcon.js";

export default () => {

    const totalValue = useSelector(getAssetsTotalValue)
    const totalGain = useSelector(getAssetsTotalGain)

    const diffTrackerValues = useSelector(getDiffTrackerValues)
    const isVisible = useSelector(getIsDiffTrackerVisible)
    const chartFocus = useSelector(getChartFocus)

    const {
        gain,
        value,
        date,
    } = diffTrackerValues

    const diffValue = totalValue - value
    const diffGain = totalGain - gain

    const isCurrentDate = isToday(date)
    const dayDiff = Math.floor(dayjs().diff(date) / (60 * 60 * 24 * 1000))

    const diff = round((chartFocus === 'gain' ? diffGain : diffValue), 5)
    const diffPct = value === 0 ? 0 : Math.abs(diff / value * 100)

    return (
        <div className={`diff-tracker hide-portrait ${isVisible && !isCurrentDate ? 'is-visible' : ''}`}>
            <div className='content'>
                <div className='timespan'>
                    <b>{numberFormat(dayDiff)}</b> day diff
                </div>
                {diff > 0 && <PlusBoldIcon className='direction-icon is-positive'/>}
                {diff === 0 && <CircleMediumIcon className='direction-icon'/>}
                {diff < 0 && <MinusThickIcon className='direction-icon is-negative'/>}
                <div className={`delta`}>
                    {numberFormat(Math.abs(diff))}
                </div>
                <div className={`delta-pct ${getDirectionColorClassName(diff)}`}>
                    {numberFormat(diffPct)}<span className='suffix'>%</span>
                </div>
            </div>
        </div>
    )
}