export const createPortfolioAggregateDateMap = transactions => {
    const dateMap = {}
    let prevDate
    for (const transaction of transactions) {
        const {date, fund_id, cost, amount, is_company} = transaction
        if (!dateMap[date]) {
            if (!prevDate) {
                dateMap[date] = {}
            } else {
                dateMap[date] = {...dateMap[prevDate]}
            }
        }
        if (!dateMap[date][fund_id]) {
            dateMap[date][fund_id] = {
                cost: 0,
                amount: 0,
                amountCompany: 0,
                costCompany: 0,
                amountPrivate: 0,
                costPrivate: 0,
            }
        }

        dateMap[date][fund_id] = {
            cost: dateMap[date][fund_id].cost + cost,
            amount: dateMap[date][fund_id].amount + amount,
            costCompany: dateMap[date][fund_id].costCompany + (is_company ? cost : 0),
            amountCompany: dateMap[date][fund_id].amountCompany + (is_company ? amount : 0),
            costPrivate: dateMap[date][fund_id].costPrivate + (!is_company ? cost : 0),
            amountPrivate: dateMap[date][fund_id].amountPrivate + (!is_company ? amount : 0),
        }

        if (date !== prevDate) {
            prevDate = date
        }
    }
    return dateMap
}