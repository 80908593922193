import {useSelector} from "react-redux";
import {getCurrentAsset} from "../../../ducks/assets-duck.js";
import {getRateHistoryForAsset} from "../../../ducks/history-duck.js";
import './RateTrajectory.scss'
import Counter from "../../util/Counter.jsx";
import {DECIMAL_FORMAT} from "../../../util/numbers.js";
import Card from "../../util/Card.jsx";

const duration = 370

const DURATIONS = [
    365,
    274,
    183,
    91,
    31,
    21,
    14,
    7,
    4,
    3,
    2,
    1,
]


export default () => {

    const {currentRate, id, value} = useSelector(getCurrentAsset)
    const rateHistory = useSelector(state => getRateHistoryForAsset(state, id))

    const rateHistorySliced = rateHistory.slice(-duration)

    const trajectoryMap = rateHistorySliced.map((date, idx) => ({
        date: date.date,
        rate: date.rate,
        idx: rateHistorySliced.length - idx,
        rateDiff: currentRate - date.rate,
        relativeRateDiff: (currentRate - date.rate) / date.rate / (rateHistorySliced.length - idx + (365 - duration)) * 365 * 100
        // relativeRateDiff: (currentRate - date.rate) / date.rate * 100
    }))

    const rates = []
    for (const duration of DURATIONS) {
        const date = trajectoryMap[trajectoryMap.length - duration - 1]
        if (date?.rate) {
            rates.push({
                ...date,
                duration,
            })
        }
        if (rates.length === 4) {
            break
        }
    }

    const absMax = rates.reduce((acc, cur) => Math.max(acc, Math.abs(cur.relativeRateDiff)), 0)

    return (
        <div className='rate-trajectory asset-kpis children-slide-right s10'>
            {rates.map(rate =>
                <KPI
                    key={rate.duration}
                    header={<><em><Duration days={rate.duration}/></em> trajectory</>}
                    value={rate.relativeRateDiff}
                    maxValue={absMax}
                >
                    <Counter
                        amount={rate.relativeRateDiff}
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                        suffix={'%'}
                        className={rate.rateDiff >= 0 ? 'is-positive' : 'is-negative'}
                        absolute={true}
                    />
                    <Counter
                        amount={rate.relativeRateDiff * value / 100}
                        decimals={DECIMAL_FORMAT.FLEX_DEC_NO_TRAIL}
                        suffix={' kr'}
                        className={`secondary`}
                        absolute={true}
                    />
                </KPI>
            )}
        </div>
    )
}

const Duration = ({days}) => {
    if (days > 30) {
        return `${Math.round(days / 30)} mth`
    }
    if (days >= 7) {
        return `${Math.round(days / 7)} week`
    }
    return `${days} day`
}

const KPI = ({
                 header = '',
                 children,
                 isVisible = true,
                 className = "",
                 isPositive,
                 isNegative,
                 value = 0,
                 maxValue = 1,
             }) => {
    if (!isVisible) {
        return null
    }
    return (
        <Card
            className={`kpi ${className}`}
            isPositive={isPositive}
            isNegative={isNegative}
        >
            <h3>
                {header}
            </h3>
            <div className='kpi-value'>
                {children}
            </div>
            <div
                className={`trajectory-bar ${value >= 0 ? 'is-positive' : 'is-negative'}`}
                style={{"--height": `${Math.abs(value / maxValue * 100)}%`}}
            />
        </Card>
    )
}