import {useDispatch, useSelector} from "react-redux";
import {getHistoryArray, getIsHistoryFullyLoaded, getLatestDateWithChange} from "../../ducks/history-duck.js";
import {getAssetIds, getFilter} from "../../ducks/assets-duck.js";
import {
    getChartDuration,
    getChartFocus,
    getUpdateKey,
    setChartHoverValues,
    setDiffTrackerValues, setIsDiffTrackerVisible
} from "../../ducks/chart-duck.js";
import {useState} from "react";

const getChartValues = (history, assetIds, prop) => {
    const values = []
    let maxValue = 0
    for (let i = 0; i < history.length; i++) {
        let value = 0
        for (const assetId of assetIds) {
            value += history[i][1][assetId][prop]
        }
        // ignore zero value days at start of dataset
        if (!!value || values.length > 0) {
            maxValue = Math.max(value, maxValue)
            values.push(value)
        }
    }
    return {
        values,
        maxValue
    }
}

const getStatistics = (valuesFull, maxValue) => {
    const latestValue = valuesFull[valuesFull.length - 1]
    let isLatestMovePositive

    const isATH = latestValue >= maxValue * .995
    for (let i = valuesFull.length - 2; i > 0; i--) {
        const value = valuesFull[i]
        if (value !== latestValue) {
            isLatestMovePositive = value < latestValue
            i = 0
        }
    }
    return {
        isATH,
        isLatestMovePositive,
    }
}

export const useChartData = () => {

    const dispatch = useDispatch()

    const history = useSelector(getHistoryArray)
    const isHistoryFullyLoaded = useSelector(getIsHistoryFullyLoaded)
    const assetIds = useSelector(getAssetIds)
    const filter = useSelector(getFilter)

    const chartDuration = useSelector(getChartDuration)
    const chartFocus = useSelector(getChartFocus)
    const updateKey = useSelector(getUpdateKey)
    const latestDateWithChange = useSelector(getLatestDateWithChange)

    const [isHovering, setIsHovering] = useState(false)

    const getPropFromDate = (prop) => (date) => assetIds.reduce((acc, cur) => acc + (date && date[1][cur] && date[1][cur][prop]), 0)

    const {values: valuesFull, maxValue} = getChartValues(history, assetIds, chartFocus)
    const {isLatestMovePositive, isATH} = getStatistics(valuesFull, maxValue)

    // ignore zero value days at start of dataset
    const historyZeroDaySlice = history.slice(history.length - valuesFull.length)

    const sliceKey = (chartDuration + 1) * -1

    const historySlicePlusOne = historyZeroDaySlice.slice(sliceKey)
    const historySlice = historySlicePlusOne.slice(sliceKey + 1)

    const labels = historySlice.map(date => date[0]).reverse()
    const values = valuesFull.slice(sliceKey + 1).reverse()

    const onMouseMove = dataIndex => {
        if (isHovering) {
            setHoverValuesFromDataIndex(dataIndex, updateKey)
        }
    }

    const setHoverValuesFromDataIndex = (dataIndex, filter, isMouseOut = false) => {
        const hoverDate = labels[dataIndex]
        const hoverValue = historySlice[historySlice.length - dataIndex - 1]
        const hoverValuePrev = historySlicePlusOne[historySlicePlusOne.length - dataIndex - 2]
        const focusValue = getPropFromDate(chartFocus)(hoverValue)
        const value = getPropFromDate('value')(hoverValue)
        const valuePrev = getPropFromDate('value')(hoverValuePrev)
        const gain = getPropFromDate('gain')(hoverValue)
        const gainPrev = getPropFromDate('gain')(hoverValuePrev)
        dispatch(setChartHoverValues({
            focusValue,
            value,
            valuePrev,
            gain,
            gainPrev,
            updateKey: filter,
            date: hoverDate,
        }))
        if (!isMouseOut) {
            dispatch(setDiffTrackerValues({
                value,
                gain,
                date: hoverDate,
            }))
        }
    }

    const onMouseOut = () => {
        const indexOf = labels.indexOf(latestDateWithChange)
        setHoverValuesFromDataIndex(indexOf, filter, true)
        setIsHovering(false)
        dispatch(setIsDiffTrackerVisible(false))
    }

    const onMouseOver = () => {
        setIsHovering(true)
    }

    return {
        isATH,
        isLatestMovePositive,
        isHistoryFullyLoaded,
        values,
        labels,
        isHovering,
        onMouseMove,
        onMouseOver,
        onMouseOut,
    }

}