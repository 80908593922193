import {
    API_ROUTE_ASSETS,
    API_ROUTE_ASSETS_LINK,
    API_ROUTE_CURRENCY_RATE,
    API_ROUTE_CURRENCY_RATE_FOR_DATE,
    API_ROUTE_PLANS,
    API_ROUTE_RATES,
    API_ROUTE_RATES_FOR_DATE,
    API_ROUTE_RATES_LIMITED,
    API_ROUTE_STATUS,
    API_ROUTE_TRANSACTION,
    API_ROUTE_TRANSACTIONS,
    API_ROUTE_TRANSACTIONS_BULK
} from "../../../back-end/constants/API-ROUTES.js";
import {deleteReq, get, patch, post} from "./_fetch.js";

export const getStatusApi = () => get(API_ROUTE_STATUS)

export const getAssetsApi = () => get(API_ROUTE_ASSETS)

export const getRatesApi = () => get(API_ROUTE_RATES)
export const postRateApi = data => post(API_ROUTE_RATES, data)

export const getRatesLimitedApi = () => get(API_ROUTE_RATES_LIMITED)
export const getRatesForDateApi = (date) => get(API_ROUTE_RATES_FOR_DATE.replace(':date', date))
export const getPlansApi = () => get(API_ROUTE_PLANS)

export const getCurrencyRate = code => get(API_ROUTE_CURRENCY_RATE.replace(':code', code))
export const getCurrencyRateForDate = (code, date) => get(API_ROUTE_CURRENCY_RATE_FOR_DATE.replace(':code', code).replace(':date', date))

export const postAssetUrl = data => post(API_ROUTE_ASSETS_LINK, data)


export const getTransactionsApi = () => get(API_ROUTE_TRANSACTIONS)
export const postTransactionApi = data => post(API_ROUTE_TRANSACTIONS, data)
export const deleteTransactionApi = transactionId =>
    deleteReq(API_ROUTE_TRANSACTION.replace(':transactionId', transactionId))
export const patchTransactionApi = (transactionId, data) =>
    patch(API_ROUTE_TRANSACTION.replace(':transactionId', transactionId), data)

export const postTransactionsBulkApi = data => post(API_ROUTE_TRANSACTIONS_BULK, data)