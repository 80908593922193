import Counter from "../Counter.jsx";
import {DECIMAL_FORMAT} from "../../../util/numbers.js";

const getClassPct = percent => {
    if (percent >= 30) {
        return 30
    }
    if (percent >= 20) {
        return 20
    }
    if (percent >= 15) {
        return 15
    }
    if (percent >= 10) {
        return 10
    }
    return Math.floor(percent)
}

export default ({
                    id,
                    shortLabel,
                    label,
                    value,
                    percent,
                    percentAccumulated,
                    setFocus,
                    setIsInfoVisible,
                    isColored = false,
                    includeRelativeValue = false,
                    relativeValueDecimals = DECIMAL_FORMAT.FLEX_PERCENTAGE,
                    absoluteValueDecimals = 0,
                    absoluteValueSuffix,
                    absoluteValuePrefix,
                    objectOnClick = () => {
                    },
                }) => {
    const percentileClassName = `pct-${getClassPct(percent)}`
    let colorClassName
    if (isColored) {
        if (value > 0) {
            colorClassName = 'is-positive'
        }
        if (value < 0) {
            colorClassName = 'is-negative'
        }
    }
    const onMouseOver = () => {
        if (percent < 10) {
            setFocus({
                label,
                shortLabel,
                value,
                percent,
                percentOffset: percentAccumulated + (percent / 2),
            })
            setIsInfoVisible(true)
        }
    }
    return (
        <div
            className={`item ${percentileClassName} ${isColored ? 'is-colored' : ''} ${colorClassName}`}
            style={{'--percent': `${percent}%`}}
            onMouseOver={onMouseOver}
            onClick={() => objectOnClick(id)}
        >
            <div className={`item-label`}>
                <div className={'code'}>
                    {shortLabel === 'OSLO' ? 'Boligen' : shortLabel}
                </div>
                <div className={'name'}>
                    {label}
                </div>
                <div className='values flex-vertical-center'>
                    {includeRelativeValue &&
                    <Counter
                        amount={percent}
                        decimals={relativeValueDecimals}
                        suffix='%'
                        className='relative'
                        noColor={true}
                        speed={0}
                    />
                    }
                    <Counter
                        amount={value}
                        decimals={absoluteValueDecimals}
                        className='absolute'
                        suffix={absoluteValueSuffix}
                        prefix={absoluteValuePrefix}
                        absolute={true}
                        noColor={true}
                        speed={0}
                    />
                </div>
            </div>
        </div>
    )
}