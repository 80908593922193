import ChartHover from "./ChartHover.jsx";
import Breadcrumbs from "./Breadcrumbs.jsx";
import {useDispatch, useSelector} from "react-redux";
import {getUpdateKey} from "../../ducks/chart-duck.js";
import './Dashboard.scss'
import Subnav from "./Subnav.jsx";
import Addendum from "./Addendum.jsx";
import Toggle from "../util/Toggle.jsx";
import {setIsAllAssetListGrouped} from "../../ducks/assets-duck.js";
import {useAssets} from "../assets/use-assets.js";

export default () => {
    const dispatch = useDispatch()
    const updateKey = useSelector(getUpdateKey)
    const {
        isAllAssetList,
        isAllAssetListGrouped,
    } = useAssets()
    return (
        <>
            <div className='dashboard' id='dashboard'>
                <ChartHover key={updateKey}/>
                <Breadcrumbs/>
            </div>
            <Addendum>
                <Subnav/>
                {isAllAssetList &&
                <div className='group-assets-toggle children-slide-right'>
                    <Toggle
                        isChecked={isAllAssetListGrouped}
                        onChange={value => dispatch(setIsAllAssetListGrouped(value))}
                    >
                        group
                    </Toggle>
                </div>
                }
            </Addendum>
        </>
    )
}