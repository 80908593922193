import Counter from "../util/Counter.jsx";
import TaxAssetRealization from "./TaxAssetRealization.jsx";
import TaxAssetHoldings from "./TaxAssetHoldings.jsx";
import {useSelector} from "react-redux";
import {getTaxReportAssets} from "../../ducks/history-duck.js";
import {orderBy} from "lodash";
import {sumProps} from "../../util/numbers.js";

export default ({year, profit, assets: assetRealizations, isDebtReductionActive = true}) => {
    const realizedAssetIds = assetRealizations.map(asset => asset.assetId)
    const assets = useSelector(state => getTaxReportAssets(state, year))
    const relevantAssets = assets.filter(asset => asset.value > 5_000 || realizedAssetIds.includes(asset.id))
    const {valuePrivate: value} = sumProps(relevantAssets, ['valuePrivate'])

    const {value: debt = 0} = assets.find(asset => asset.id === 104) || {} // DAI
    const debtRatio = isDebtReductionActive ? 1 - Math.abs(debt / value) : 1

    const relevantAssetsOrder = orderBy(relevantAssets, ['name'])
    const assetRealizationsOrder = orderBy(assetRealizations, ['assetName'])

    return (
        <>
            <tr>
                <td className='year' colSpan={100}>
                    {year}
                </td>
            </tr>
            <tr>
                <td className='strategy' colSpan={100}>
                    Holdings &nbsp;<span className='dull'>per 31/12</span>
                </td>
            </tr>
            <tr>
                <th>
                    asset
                </th>
                <th className='numeric'>
                    holding
                    <div className='dull'>
                        per 31/12
                    </div>
                </th>
                <th className='numeric'>
                    price
                    <div className='dull'>
                        per 31/12, NOK
                    </div>
                </th>
                <th className='numeric'>
                    value
                    <div className='dull'>
                        per 31/12, NOK
                    </div>
                </th>
            </tr>
            {relevantAssetsOrder.map(asset => <TaxAssetHoldings key={asset.id} debtRatio={debtRatio} {...asset} />)}
            <tr>
                <td className={`year-profit numeric`} colSpan={4}>
                    <Counter
                        amount={value * debtRatio}
                        decimals={2}
                    />
                </td>
            </tr>
            <tr>
                <td className='strategy' colSpan={100}>
                    Realizations &nbsp;<span className='dull'>FIFO - First In First Out</span>
                </td>
            </tr>
            <tr>
                <th>
                    asset
                </th>
                <th className='numeric'>
                    quantity sold
                    <div className='dull'>
                        during {year}
                    </div>
                </th>
                <th className='numeric'>
                    amount purchase
                    <div className='dull'>
                        total, NOK
                    </div>
                </th>
                <th className='numeric'>
                    price purchase
                    <div className='dull'>
                        average, NOK
                    </div>
                </th>
                <th className='numeric'>
                    amount sale
                    <div className='dull'>
                        total, NOK
                    </div>
                </th>
                <th className='numeric'>
                    price sale
                    <div className='dull'>
                        average, NOK
                    </div>
                </th>
                <th className='numeric'>
                    profit/loss
                    <div className='dull'>
                        NOK
                    </div>
                </th>
            </tr>
            {assetRealizationsOrder.map(asset => <TaxAssetRealization key={asset.assetId} year={year} {...asset} />)}
            <tr>
                <td className={`year-profit numeric ${profit >= 0 ? 'is-positive' : 'is-negative'}`} colSpan={100}>
                    <Counter
                        amount={profit}
                        decimals={2}
                    />
                </td>
            </tr>
        </>
    )
}