import dayjs from "dayjs";
import {groupBy} from "lodash";
import {sumProps} from "../../util/numbers.js";

const _createTransactionGroup = () => ({
    ins: [],
    outs: [],
})

export const getRealisationStack = (transactions, assetId) => {
    const transactionsByAsset = groupTransactionsByAsset(transactions)
    const {ins, outs} = transactionsByAsset[assetId] || {}
    const {stack} = getFifoTaxEvents(ins, outs)
    return stack
}

export const calculateTaxEvents = transactions => {
    const taxEvents = []
    const transactionsByAsset = groupTransactionsByAsset(transactions)
    for (const {ins, outs} of Object.values(transactionsByAsset)) {
        const { events } = getFifoTaxEvents(ins, outs)
        taxEvents.push(...events)
    }
    const taxEventYears = groupTaxEventsByYear(taxEvents)
    for (const key of Object.keys(taxEventYears)) {
        const events = taxEventYears[key]
        const sums = sumProps(events, ['profit'])
        const assets = groupTaxEventYearByAssets(events)
        taxEventYears[key] = {
            assets,
            year: key,
            ...sums,
        }
    }
    return [...Object.values(taxEventYears).reverse()]
}

const groupTaxEventsByYear = taxEvents =>
    groupBy(taxEvents, 'yearSell')

const groupTaxEventYearByAssets = taxEventYear => {
    const assets = groupBy(taxEventYear, 'assetId')
    for (const key of Object.keys(assets)) {
        const events = assets[key]
        const [firstEvent] = events
        const {assetName, assetCode, assetId} = firstEvent
        const sums = sumProps(events, ['profit', 'quantity', 'paidAmount', 'soldAmount'])
        assets[key] = {
            assetId,
            assetName,
            assetCode,
            events,
            ...sums,
        }
    }
    return Object.values(assets)
}


export const groupTransactionsByAsset = transactions =>
    transactions.reduce((acc, cur) => {
        const {cost, amount, fund_id} = cur
        if (!acc[fund_id]) {
            acc[fund_id] = _createTransactionGroup()
        }
        const isIn = amount > 0 || cost > 0
        const isOut = !isIn
        return {
            ...acc,
            [fund_id]: {
                ins: isIn ? [...acc[fund_id].ins, cur] : acc[fund_id].ins,
                outs: isOut ? [...acc[fund_id].outs, cur] : acc[fund_id].outs,
            },
        }
    }, {})

const _createTaxEvent = (txIn, txOut, sellAmount) => {
    const {date: dateBuy, comment: commentBuy, rate: buyRate} = txIn
    const {date: dateSell, comment: commentSell, fund_id, asset_code, asset_name, rate: sellRate} = txOut
    const paidAmount = (sellAmount * buyRate)
    const soldAmount = sellAmount * sellRate
    const profit = sellAmount === 0 ? txOut.cost * -1 : soldAmount - paidAmount
    return {
        profit,
        paidAmount,
        soldAmount,
        quantity: sellAmount,
        dateBuy,
        dateSell,
        commentBuy,
        commentSell,
        buyRate,
        sellRate,
        assetId: fund_id,
        assetCode: asset_code,
        assetName: asset_name,
        yearSell: parseInt(dayjs(dateSell).format("YYYY")),
        txIn,
        txOut,
    }
}

export const getFifoTaxEvents = (ins = [], outs = []) => {
    const events = []
    const stack = [...ins]
    for (const txOut of outs) {
        const {amount, rate} = txOut
        let amountRemaining = Math.abs(amount)
        if(amount === 0 && Math.abs(txOut.cost) >= 1_000){
            events.push(_createTaxEvent({ rate: 0, date: txOut.date }, txOut, 0))
        }
        while (amountRemaining > 0) {
            if (stack.length === 0) {
                const valueRemaining = amountRemaining * rate
                valueRemaining > 1 && console.error("OOOPS, ran out of ins for this out :|", txOut, {
                    amountRemaining,
                    valueRemaining: amountRemaining * rate,
                })
                break
            }
            const txIn = {...stack.shift()}
            const {amount} = txIn
            if (amount > amountRemaining) {
                txIn.amount -= amountRemaining
                stack.unshift(txIn)
                events.push(_createTaxEvent(txIn, txOut, amountRemaining))
                amountRemaining = 0
            } else {
                amountRemaining -= txIn.amount
                events.push(_createTaxEvent(txIn, txOut, txIn.amount))
            }
        }
    }
    return {
        events,
        stack,
    }
}