import LoaderHorizontal from "./LoaderHorizontal.jsx";
import LoaderCircle from "./LoaderCircle.jsx";

export const LoaderWrapperHorizontal = ({isLoading = false, className = '', children}) => {
    return isLoading ?
        <div className={`loader-wrapper ${className}`}>
            <LoaderHorizontal/>
        </div>
        :
        children
}

export const LoaderWrapperCircle = ({
                                        isLoading = false,
                                        className = '',
                                        isPositive = false,
                                        isGolden = false,
                                        children,
                                    }) => {
    return isLoading ?
        <div className={`loader-wrapper ${className}`}>
            <LoaderCircle className={`${isPositive ? 'is-positive' : 'is-negative'} ${isGolden ? 'is-golden' : ''}`} />
        </div>
        :
        children
}