import {useDispatch, useSelector} from "react-redux";
import {getChartHoverDate} from "../../ducks/chart-duck.js";
import {useChillEffect} from "../../util/mounting-hooks.js";
import {getFilterPreset, setDateFilter} from "../../ducks/assets-duck.js";
import {scrollToTop, useNavigation} from "../util/navigation/navigation.js";
import {isToday} from "../../util/time-and-date.js";

export default () => {

    const dispatch = useDispatch()
    const date = useSelector(getChartHoverDate)
    const filterPreset = useSelector(getFilterPreset)
    const {navigateToDashBoard} = useNavigation(scrollToTop)

    useChillEffect(()=>{
        !isToday(date) && dispatch(setDateFilter(date))
        navigateToDashBoard(filterPreset)
    },[date])

    return null
}