import {useSelector} from "react-redux";
import {getComparativeAssetRate} from "../../ducks/assets-duck.js";
import {getIsHistoryFullyLoaded} from "../../ducks/history-duck.js";
import {getSubPathCache} from "../../ducks/user-duck.js";
import {APP_ROUTES} from "../../App.jsx";
import {scrollToTop, useNavigation} from "../util/navigation/navigation.js";
import {getDirectionColorClassName} from "../../util/misc.js";
import dayjs from "dayjs";
import ChangeFlavorIcon from "../util/icons/ChangeFlavorIcon.jsx";
import {DECIMAL_FORMAT, numberFormat} from "../../util/numbers.js";
import Counter from "../util/Counter.jsx";
import DirectionIcon from "../util/icons/DirectionIcon.jsx";
import AssetIcon from "../util/icons/AssetIcon.jsx";

export default ({
                    id,
                    name,
                    code,
                    value,
                    is_usd_asset,
                    rateUsdAth,
                    currentRate,
                    currentRateDate,
                    rateAth,
                    currentRateUsd,
                    changePct,
                    gain,
                    gainPct,
                    superGainPct,
                    isMostRecentUpdate,
                    ratio = 0,
                    isGroup = false,
                    isHistoricDate = false,
                }) => {

    const comparativeAssetRate = useSelector(getComparativeAssetRate)
    const isHistoryFullLoaded = useSelector(getIsHistoryFullyLoaded)
    const subPathCache = useSelector(getSubPathCache)

    const prevVale = value / (1 + changePct)
    const latestGainChange = value - prevVale

    const isAth = is_usd_asset ?
        currentRateUsd > rateUsdAth * .995
        :
        currentRate > rateAth * .995

    let route = subPathCache ? APP_ROUTES.history.replace('history', subPathCache) : APP_ROUTES.history
    if (isGroup) {
        route = APP_ROUTES.filter.replace(':filterId', id)
    }
    const {navigateTo} = useNavigation(scrollToTop)


    const secondsSinceRate = dayjs().diff(dayjs(currentRateDate))
    const daysSinceRate = Math.floor(secondsSinceRate / (60 * 60 * 24 * 1000))

    const isMultiColored = daysSinceRate <= 5
    const changeClassName = getDirectionColorClassName(changePct, isMultiColored)

    return (
        <tr onClick={() => navigateTo(route, {filterId: id})}>
            <td className='fade-left hide-portrait'/>
            <td className='asset-name'>
                <div className='flex-vertical-center'>
                    {isGroup &&
                    <AssetIcon
                        assetType={id}
                        className='asset-icon std-icon'
                    />
                    }
                    <span className='name'>
                        {name}
                    </span>
                    {isMultiColored &&
                    <ChangeFlavorIcon amount={changePct}/>
                    }
                    <span className='code'>
                        {!isGroup && code}
                    </span>
                </div>
                <div className='small hide-desktop'>
                    <div className='flex-vertical-center rate'>
                        {isGroup &&
                        <Counter
                            amount={ratio * 100}
                            decimals={DECIMAL_FORMAT.FLEX_DEC}
                            suffix={'%'}
                            className={'asset-ratio'}
                        />
                        }
                        {daysSinceRate > 0 &&
                        <div className='rate-freshness'>
                            {numberFormat(daysSinceRate, 0)}
                        </div>
                        }
                        {!isGroup &&
                        <Counter
                            amount={is_usd_asset ? currentRateUsd : currentRate}
                            decimals={DECIMAL_FORMAT.FLEX_DEC}
                            prefix={is_usd_asset ? '$ ' : 'kr '}
                            className={isAth && isHistoryFullLoaded ? 'is-golden' : ''}
                        />
                        }
                    </div>
                </div>
            </td>
            <td className='numeric hide-portrait rate'>
                <div className='flex-vertical-center'>
                    {daysSinceRate > 0 && !isHistoricDate &&
                    <div className='rate-freshness'>
                        {numberFormat(daysSinceRate, 0)}
                    </div>
                    }
                    {!isGroup &&
                    <Counter
                        amount={is_usd_asset ? currentRateUsd : currentRate}
                        decimals={DECIMAL_FORMAT.FLEX_DEC}
                        prefix={is_usd_asset ? '$ ' : 'kr '}
                        className={isAth && isHistoryFullLoaded ? 'is-golden' : ''}
                    />
                    }
                    {isGroup &&
                    <Counter
                        amount={ratio * 100}
                        decimals={DECIMAL_FORMAT.FLEX_DEC}
                        suffix={'%'}
                    />
                    }
                </div>
            </td>
            <td className='numeric hide-portrait'>
                <Counter
                    amount={value / comparativeAssetRate}
                />
            </td>
            <td className='numeric hide-portrait'>
                <Counter
                    amount={value}
                />
            </td>
            <td className={`numeric hide-portrait`}>
                <DirectionIcon value={gain}/>
                <Counter
                    amount={gain}
                    absolute={true}
                />
                {(superGainPct || gainPct) && Math.abs(gainPct) !== Infinity &&
                <div
                    className={`small ${gainPct > 0 ? 'is-positive' : 'is-negative'} ${superGainPct > 0 ? 'is-golden' : ''}`}>
                    <Counter
                        amount={(superGainPct || gainPct) * 100}
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                        absolute={true}
                        suffix={'%'}
                    />
                </div>
                }
            </td>
            <td className={`numeric ${!isMostRecentUpdate && 'old-rate'}`}>
                <DirectionIcon
                    value={changePct}
                    isMultiColored={isMultiColored}
                />
                <Counter
                    amount={latestGainChange}
                    decimals={0}
                    absolute={true}
                />
                <div className={`small ${changeClassName}`}>
                    <Counter
                        amount={changePct * 100}
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                        absolute={true}
                        suffix={'%'}
                    />
                </div>
            </td>
            <td className='fade-right hide-portrait'/>
        </tr>
    )
}