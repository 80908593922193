import {postAuthApi} from "../../api/authApi.js";
import {useState} from "react";
import './login.scss'
import {useDispatch} from "react-redux";
import {setToken} from "../../ducks/user-duck.js";
import Button from "../util/Button.jsx";
import ChevronRightIcon from "mdi-react/ChevronRightIcon.js";

export default () => {

    const dispatch = useDispatch()

    const [username, setUsername] = useState(process.env.REACT_APP_PRE_USERNAME || "")
    const [password, setPassword] = useState(process.env.REACT_APP_PRE_PASSWORD || "")

    const isFormValid = !!username && !!password

    const login = async () => {
        try {
            const {token} = await postAuthApi(username, password)
            dispatch(setToken(token))
        } catch (all) {
            const {error} = all
            console.log("error", error, all)
        }
    }

    return (
        <div className='login'>
            <div className='input-row'>
                <input
                    type="text"
                    placeholder="username"
                    value={username}
                    onChange={e => setUsername(e.target.value)}
                />
            </div>
            <div className='input-row'>
                <input
                    type="password"
                    placeholder="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
            </div>
            <div className='button-row'>
                <Button
                    onClick={login}
                    disabled={!isFormValid}
                    icon={<ChevronRightIcon/>}
                    isIconRight={true}
                >
                    Login
                </Button>
            </div>
        </div>
    )
}