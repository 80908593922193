import Counter from "../util/Counter.jsx";

export default ({
                    name,
                    code,
                    rate,
                    amountPrivate,
                    valuePrivate,
                    debtRatio = 1,
                }) => {

    return (
        <tr>
            <td>
                {name}
            </td>
            <td className='numeric'>
                <Counter
                    amount={amountPrivate * debtRatio}
                    decimals={2}
                    suffix={code}
                />
            </td>
            <td className='numeric'>
                <Counter
                    amount={rate}
                    decimals={2}
                />
            </td>
            <td className='numeric'>
                <Counter
                    amount={valuePrivate * debtRatio}
                    decimals={2}
                />
            </td>
        </tr>
    )
}