import {useSelector} from "react-redux";
import {CHART_FOCUSES, getChartFocus, getChartHoverDate, getChartHoverValues} from "../../ducks/chart-duck.js";
import dayjs from "dayjs";
import Counter from "../util/Counter.jsx";
import {DECIMAL_FORMAT} from "../../util/numbers.js";
import './ChartHover.scss'
import DirectionIcon from "../util/icons/DirectionIcon.jsx";
import DateDiffHighlighter from "../util/DateDiffHighlighter.jsx";
import GainPct from "../util/GainPct.jsx";
import {getDirectionColorClassName} from "../../util/misc.js";
import DiffTracker from "./DiffTracker.jsx";

export default () => {

    const chartHoverDate = useSelector(getChartHoverDate)
    const chartHoverValues = useSelector(getChartHoverValues)
    const chartFocus = useSelector(getChartFocus)

    const {
        focusValue,
        valuePrev,
        gain,
        value,
        gainPrev,
        updateKey,
    } = chartHoverValues

    const updateKeyDated = `${chartHoverDate}|${updateKey}`
    const gainChange = gain - gainPrev
    const changePercent = ((gain - gainPrev) / valuePrev) || 0
    const isCurrentDate = chartHoverDate === dayjs().format('YYYY-MM-DD')

    const header = chartFocus === 'gain' && focusValue < 0 ? 'loss' : CHART_FOCUSES[chartFocus].name

    return (
        <div className='chart-hover'>
            <DiffTracker/>
            <div className='kpis hide-desktop'>
                <KPI
                    className={`focus-value ${value < 0 ? 'is-negative' : ''}`}
                    header={isCurrentDate ? header : <DateDiffHighlighter date={chartHoverDate}/>}
                >
                    {chartFocus === 'gain' ?
                        <Counter
                            amount={gain}
                            key={updateKeyDated + "mob-gain"}
                            absolute={true}
                            decimals={DECIMAL_FORMAT.FLEX}
                        />
                        :
                        <Counter
                            amount={value}
                            key={updateKeyDated + "mob-value"}
                            absolute={true}
                            decimals={DECIMAL_FORMAT.FLEX}
                        />
                    }
                    {chartFocus === 'gain' &&
                    <div className='pct'>
                        <GainPct
                            key={updateKeyDated + "mob2"}
                            value={value}
                            gain={gain}
                        />
                    </div>
                    }
                </KPI>
                <KPI
                    className='change'
                    header={'change'}
                >
                    <div className='arrow'>
                        <DirectionIcon
                            value={changePercent}
                            isMultiColored={true}
                        />
                    </div>
                    <div className='abs'>
                        <Counter
                            amount={gainChange}
                            key={updateKeyDated + 5}
                            absolute={true}
                        />
                    </div>
                    <div className={`pct ${getDirectionColorClassName(changePercent, true)}`}>
                        <Counter
                            amount={changePercent * 100}
                            key={updateKeyDated + 6}
                            decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                            suffix='%'
                            absolute={true}
                        />
                    </div>
                </KPI>
            </div>
            <div className='kpis hide-portrait'>
                <KPI
                    className={`focus-value ${value < 0 ? 'is-negative' : ''}`}
                    header={isCurrentDate ? 'value' : <DateDiffHighlighter date={chartHoverDate}/>}
                >
                    <Counter
                        amount={value}
                        key={updateKeyDated + 2}
                        absolute={true}
                    />
                </KPI>
                <KPI
                    header={gain < 0 ? 'loss' : 'return'}
                >
                    <div>
                        <Counter
                            amount={gain}
                            key={updateKeyDated + 3}
                            absolute={true}
                        />
                    </div>
                    <div className='pct'>
                        <GainPct
                            key={updateKeyDated + 4}
                            value={value}
                            gain={gain}
                        />
                    </div>
                </KPI>
                <KPI
                    className='change'
                    header={'change'}
                >
                    <div className='arrow'>
                        <DirectionIcon
                            value={changePercent}
                            isMultiColored={true}
                        />
                    </div>
                    <div className='abs'>
                        <Counter
                            amount={gainChange}
                            key={updateKeyDated + 5}
                            absolute={true}
                        />
                    </div>
                    <div className={`pct ${getDirectionColorClassName(changePercent, true)}`}>
                        <Counter
                            amount={changePercent * 100}
                            key={updateKeyDated + 6}
                            decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                            suffix='%'
                            absolute={true}
                        />
                    </div>
                </KPI>
            </div>
        </div>
    )
}

const KPI = ({header, className = "", children}) => {
    return (
        <div className={`kpi ${className}`}>
            <h3>
                {header}
            </h3>
            <div className={`kpi-value`}>
                {children}
            </div>
        </div>
    )
}