import {getTransactionsFiltered} from "../../../ducks/transactions-duck.js";
import {useSelector} from "react-redux";
import './AssetTransactions.scss'
import Counter from "../../util/Counter.jsx";
import Table from "../../util/table/Table.jsx";
import orderBy from "lodash/orderBy.js";
import {DECIMAL_FORMAT} from "../../../util/numbers.js";
import useLazyListLoader from "../../util/lasy-loader/useLazyListLoader.js";
import DirectionIcon from "../../util/icons/DirectionIcon.jsx";
import InternalLink from "../../util/navigation/InternalLink.jsx";
import DateDiffHighlighter from "../../util/DateDiffHighlighter.jsx";
import {getAssetRate} from "../../../ducks/assets-duck.js";
import GasIcon from "mdi-react/GasIcon.js";
import {APP_ROUTES} from "../../../App.jsx";
import PlusIcon from "mdi-react/PlusIcon.js";
import {scrollToDashBoard, useNavigation} from "../../util/navigation/navigation.js";
import FilterToggleButton from "./FilterToggleButton.jsx";
import BriefcaseAccountOutlineIcon from "mdi-react/BriefcaseAccountOutlineIcon.js";
import AdditiveIcon from "../../util/icons/AdditiveIcon.jsx";
import ArrowRightIcon from "mdi-react/ArrowRightIcon.js";

export default () => {

    const transactions = useSelector(getTransactionsFiltered)
    const transactionsOrdered = orderBy(transactions, ['date'], ['desc'])

    const {visibleList, ListLoaderComponent} = useLazyListLoader(transactionsOrdered)

    return (
        <>
            <div className='asset-transactions'>
                <div className='options-bar flex-vertical-center'>
                    <div>
                        <InternalLink
                            to={APP_ROUTES.transactionsAdd}
                            isButton={true}
                            icon={<PlusIcon/>}
                        >
                            <span className='hide-portrait'>add transaction</span>
                            <span className='hide-desktop'>add</span>
                        </InternalLink>
                    </div>
                    <div className='filters'>
                        <FilterToggleButton
                            icon={<GasIcon/>}
                            prop='filterBurn'
                        />
                        <FilterToggleButton
                            icon={<BriefcaseAccountOutlineIcon/>}
                            prop='filterCompany'
                        />
                        {/*<FilterToggleButton*/}
                        {/*    icon={<CommentOutlineIcon/>}*/}
                        {/*    prop='filterComment'*/}
                        {/*/>*/}
                    </div>
                    {/*<Button onClick={()=>setup(dispatch, true)}>*/}
                    {/*    Re-load*/}
                    {/*</Button>*/}
                </div>
                {visibleList.length > 0 &&
                <Table>
                    <thead>
                    <tr>
                        <th className='hide-portrait'/>
                        <th className='hide-portrait'>Date</th>
                        <th>Asset</th>
                        <th className='hide-portrait numeric'>Cost&nbsp;</th>
                        <th className='hide-portrait numeric'>Qty</th>
                        <th className='hide-desktop numeric'>Swap</th>
                        <th className='numeric'>Return</th>
                        <th className='hide-portrait'/>
                    </tr>
                    </thead>
                    <tbody>
                    {visibleList.map(tx => <Transaction key={tx.id} {...tx}/>)}
                    </tbody>
                </Table>
                }
                <ListLoaderComponent/>
            </div>
        </>
    )
}

const Transaction = ({
                         id,
                         asset_name,
                         asset_code,
                         cost,
                         amount,
                         date,
                         fund_id,
                         is_gas_cost,
                         is_dividend,
                         comment,
                         is_company,
                         childTx,
                     }) => {

    const {
        fund_id: fundIdChild,
        amount: amountChild,
        cost: costChild,
        asset_code: assetCodeChild,
    } = childTx || {}
    const {navigateTo} = useNavigation(scrollToDashBoard)
    const currentRate = useSelector(state => getAssetRate(state, fund_id))
    const currentRateChild = useSelector(state => getAssetRate(state, fundIdChild))


    const currentValue = currentRate * amount
    const gain = currentValue - cost

    const currentValueChild = currentRateChild * amountChild
    const gainChild = currentValueChild - costChild

    const realGain = gainChild ? gainChild + gain : gain

    let className = cost >= 0 ? 'is-buy' : 'is-sell'
    if (is_gas_cost) {
        className = 'is-gas-cost'
    }
    if (is_dividend) {
        className = 'is-dividend'
    }

    const isDividend = amountChild && !amount
    const isSwap = !isDividend && amountChild
    const realAmount = isDividend ? amountChild : amount

    return (
        <tr onClick={() => navigateTo(APP_ROUTES.transactionsPage, {transactionId: id})}>
            <td className='fade-left hide-portrait'/>
            <td className='hide-portrait date'>
                <DateDiffHighlighter date={date}/>
            </td>
            <td className='asset-name'>
                <div className='flex-vertical-center flex-box'>
                    {isSwap &&
                    <>
                        <div className='swap-from'>{assetCodeChild}</div>
                        <ArrowRightIcon className='std-icon swap-icon'/>
                    </>
                    }
                    <div>{asset_name}</div>
                    {!!is_company && <BriefcaseAccountOutlineIcon className='std-icon'/>}
                </div>
                <div className='small hide-desktop'>
                    <DateDiffHighlighter date={date}/>
                </div>
                {!!comment &&
                <div className='small hide-portrait comment'>
                    {comment}
                </div>
                }
            </td>
            <td className={`numeric hide-portrait ${className} ${cost === 0 ? 'is-zero' : ''}`}>
                {isSwap ?
                    <div>
                        <AdditiveIcon amount={cost * -1}/>
                        <Counter
                            amount={amountChild}
                            absolute={true}
                            decimals={0}
                            className='cost-full hide-portrait'
                            suffix={` ${assetCodeChild}`}
                        />
                    </div>
                    :
                    <div>
                        <AdditiveIcon amount={cost * -1}/>
                        <Counter
                            amount={cost}
                            absolute={true}
                            decimals={0}
                            className='cost-full hide-portrait'
                            suffix=' kr'
                        />
                    </div>
                }
            </td>
            <td className={`numeric hide-portrait ${className} ${realAmount === 0 ? 'is-zero' : ''}`}>
                <AdditiveIcon amount={realAmount}/>
                <Counter
                    amount={realAmount}
                    absolute={true}
                    decimals={DECIMAL_FORMAT.FLEX_DEC}
                    suffix={` ${asset_code}`}
                    className='qty-full'
                />
            </td>
            <td className={`numeric hide-desktop ${className}`}>
                <div className='increment'>
                    <AdditiveIcon amount={1}/>
                    <Counter
                        amount={cost <= 0 ? cost : realAmount}
                        absolute={true}
                        decimals={DECIMAL_FORMAT.FLEX}
                        suffix={cost <= 0 ? ' kr' : ` ${asset_code}`}
                    />
                </div>
                <div className='decrement'>
                    <AdditiveIcon amount={!isDividend ? -1 : 1}/>
                    <Counter
                        amount={cost <= 0 ? realAmount : cost}
                        absolute={true}
                        decimals={DECIMAL_FORMAT.FLEX}
                        suffix={cost <= 0 ? ` ${asset_code}` : ' kr'}
                    />
                </div>
            </td>
            <td className='numeric'>
                {is_gas_cost ? <GasIcon className='direction-icon is-gas'/> : <DirectionIcon value={realGain}/>}
                <Counter
                    amount={realGain}
                    absolute={true}
                />
                <div
                    className={`
                            small
                            ${realGain >= 0 ? 'is-positive' : 'is-negative'}
                            ${realGain === 0 || is_gas_cost ? 'is-zero' : ''}
                            ${is_dividend ? 'is-dividend' : ''}
                    `}
                >
                    <Counter
                        amount={is_dividend ? 100 : realGain / cost * 100}
                        absolute={true}
                        decimals={DECIMAL_FORMAT.FLEX_PERCENTAGE}
                        suffix='%'
                    />
                </div>
            </td>
            <td className='fade-right hide-portrait'/>
        </tr>
    )
}