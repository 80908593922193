import {useCryptoBalances} from "./_use-crypto-balances.js";
import './CryptoBalances.scss'
import Card from "../util/Card.jsx";
import ExternalLink from "../util/ExternalLink.jsx";
import Counter from "../util/Counter.jsx";
import {DECIMAL_FORMAT} from "../../util/numbers.js";
import useLazyListLoader from "../util/lasy-loader/useLazyListLoader.js";
import {formatEthereumAddress} from "../../util/misc.js";
import {getUsdRate} from "../../ducks/assets-duck.js";
import {useSelector} from "react-redux";

export default () => {
    const {accounts = []} = useCryptoBalances()
    const usdRate = useSelector(getUsdRate)

    const totalValue = accounts.reduce((acc, cur) => acc + cur.totalValue, 0)

    const {visibleList, ListLoaderComponent} = useLazyListLoader(accounts, 10, 3)
    return (
        <div className='crypto-balances'>
            <Card className='total-value'>
                <h3>
                    Sum value all assets
                </h3>
                <div className='flex-vertical-center value'>
                    <Counter
                        amount={totalValue}
                        suffix='kr'
                    />
                    <Counter
                        className='usd-value'
                        amount={totalValue / usdRate}
                        decimals={DECIMAL_FORMAT.FLEX}
                        prefix='$'
                    />
                </div>
            </Card>
            {visibleList.map(account =>
                <Account
                    key={account.address}
                    usdRate={usdRate}
                    {...account}
                />
            )}
            <ListLoaderComponent/>
        </div>
    )
}

const Account = ({comment, address, totalValue, location, usdRate = 0, assets = []}) => {
    return (
        <Card className='account'>
            <div className='header'>
                <div className='description'>
                    <div className='comment'>
                        {comment}
                    </div>
                    <div className='value flex-vertical-center'>
                        <Counter
                            amount={totalValue}
                            decimals={0}
                            suffix={'kr'}
                        />
                        <Counter
                            className='usd-value'
                            amount={totalValue / usdRate}
                            decimals={DECIMAL_FORMAT.FLEX}
                            prefix='$'
                        />
                    </div>
                </div>
                <div>
                    <div className='address flex-vertical-center'>
                        <ExternalLink href={`https://debank.com/profile/${address}`} className='debank-link'>
                            DB
                        </ExternalLink>
                        <ExternalLink href={`https://etherscan.io/address/${address}`}>
                            {formatEthereumAddress(address, 5)}
                        </ExternalLink>
                    </div>
                    <div className='location'>
                        {location}
                    </div>
                </div>
            </div>
            <div className='asset-balances flex-vertical-center'>
                {assets.map(asset => <Asset key={asset.tokenAddress} address={address} {...asset} />)}
            </div>
        </Card>
    )
}

const Asset = ({code, value, balance, tokenAddress, address}) => {
    return (
        <ExternalLink
            href={`https://etherscan.io/token/${tokenAddress}?a=${address}`}
            className='asset-balance'
        >
            <div className='value'>
                <Counter
                    amount={value}
                    decimals={0}
                    suffix='kr'
                />
            </div>
            <div className='quantity'>
                <Counter
                    amount={balance}
                    decimals={DECIMAL_FORMAT.FLEX_DEC}
                    suffix={code}
                />
            </div>
        </ExternalLink>
    )
}