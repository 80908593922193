import {useSelector} from "react-redux";
import {getAssets, getIsSingleAsset} from "../../../ducks/assets-duck.js";
import {getTaxReportTransactions} from "../../../ducks/transactions-duck.js";
import {getRealisationStack} from "../../tax-report/_helpers.js";
import Card from "../../util/Card.jsx";
import './OffloadingGauge.scss'
import Table from "../../util/table/Table.jsx";
import {DECIMAL_FORMAT, numberFormat, sumProps} from "../../../util/numbers.js";
import Counter from "../../util/Counter.jsx";
import {getDirectionColorClassName} from "../../../util/misc.js";
import {useState} from "react";
import TrClickable from "../../util/table/TrClickable.jsx";
import ChevronUpIcon from "mdi-react/ChevronUpIcon.js";
import ChevronDownIcon from "mdi-react/ChevronDownIcon.js";

// aksjer/fond: 37,84%
// crypto: 22%

const LOW_TAX_RATE = 0.22
const HIGH_TAX_RATE = 0.3784

export default () => {

    const [isExpanded, setIsExpanded] = useState(false)
    const isSingleAsset = useSelector(getIsSingleAsset)
    const transactions = useSelector(getTaxReportTransactions)
    const [asset] = useSelector(getAssets)
    const {currentRate, id: assetId, asset_type} = asset
    const isHighTax = asset_type.includes('STOCK') || asset_type.includes('FUND')

    const stack = getRealisationStack(transactions, assetId)

    const calculatedStack = stack.map(tx => {
        const taxableGains = (tx.amount * currentRate) - (tx.amount * tx.rate)
        const taxRate = isHighTax ? HIGH_TAX_RATE : LOW_TAX_RATE
        return {
            ...tx,
            taxableGains,
            taxRate,
            taxableGainsPerUnit: taxableGains / tx.amount,
            tax: taxableGains * taxRate,
        }
    })

    const {amount: quantity, taxableGains, tax} = sumProps(calculatedStack, ['amount', 'taxableGains', 'tax'])

    const listIsCroppable = calculatedStack.length > 3
    const listIsCropped = listIsCroppable && !isExpanded
    const visibleStack = listIsCropped ? calculatedStack.slice(0, 2) : calculatedStack

    return isSingleAsset && stack.length > 0 && (
        <Card className='offloading-gauge'>
            <h2>
                Offloading gauge
            </h2>
            <Table isInCard={true}>
                <thead>
                <tr>
                    <th className='hide-portrait'>
                        buy date
                    </th>
                    <th className='numeric'>
                        quantity
                    </th>
                    <th className='numeric'>
                        taxable
                    </th>
                    <th className='numeric hide-portrait'>
                        tax/qty
                    </th>
                    <th className='numeric'>
                        tax
                    </th>
                </tr>
                </thead>
                <tbody>
                {visibleStack.map(realisation =>
                    <Realisation
                        key={stack.id}
                        currentRate={currentRate}
                        isHighTax={isHighTax}
                        {...realisation}
                    />
                )}
                {listIsCroppable && !listIsCropped &&
                <TrClickable onClick={() => setIsExpanded(false)}>
                    <td colSpan={100} className='center'>
                        <div className='flex-vertical-center justify-center'>
                            <div>
                                hide
                            </div>
                            <ChevronUpIcon />
                        </div>
                    </td>
                </TrClickable>
                }
                {listIsCroppable && listIsCropped &&
                <TrClickable onClick={() => setIsExpanded(true)}>
                    <td colSpan={100} className='center'>
                        <div className='flex-vertical-center justify-center'>
                            <div>
                                show <b>{numberFormat(calculatedStack.length - visibleStack.length)}</b> more
                            </div>
                            <ChevronDownIcon />
                        </div>
                    </td>
                </TrClickable>
                }
                {calculatedStack.length > 1 &&
                <tr className='totals'>
                    <td className='hide-portrait' />
                    <td className='numeric'>
                        <Counter amount={quantity} decimals={DECIMAL_FORMAT.FLEX}/>
                    </td>
                    <td className='numeric'>
                        <Counter amount={taxableGains} decimals={DECIMAL_FORMAT.FLEX}/>
                    </td>
                    <td className='hide-portrait' />
                    <td className='numeric'>
                        <Counter
                            amount={tax}
                            decimals={DECIMAL_FORMAT.FLEX}
                            absolute={true}
                            className={getDirectionColorClassName(taxableGains * -1)}
                        />
                    </td>
                </tr>
                }
                </tbody>
            </Table>
        </Card>
    )
}

export const Realisation = ({date, amount: quantity, taxableGains, taxableGainsPerUnit, tax}) => {
    return (
        <tr>
            <td className='hide-portrait'>
                {date}
            </td>
            <td className='numeric'>
                <Counter amount={quantity} decimals={DECIMAL_FORMAT.FLEX}/>
            </td>
            <td className='numeric'>
                <Counter amount={taxableGains} decimals={DECIMAL_FORMAT.FLEX}/>
            </td>
            <td className='numeric hide-portrait'>
                <Counter amount={taxableGainsPerUnit} decimals={DECIMAL_FORMAT.FLEX}/>
            </td>
            <td className='numeric'>
                <Counter
                    amount={tax}
                    decimals={DECIMAL_FORMAT.FLEX}
                    className={getDirectionColorClassName(taxableGains * -1)}
                />
            </td>
        </tr>
    )
}