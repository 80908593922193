import './ChartDuration.scss'
import {CHART_DURATIONS, getChartDuration, setChartDuration} from "../../ducks/chart-duck.js";
import {useDispatch, useSelector} from "react-redux";

export default () => {
    return (
        <div className='chart-duration'>
            <div className='buttons children-slide-down'>
                {Object.keys(CHART_DURATIONS).map(id => <Duration key={id} id={id} />)}
            </div>
        </div>
    )
}

const Duration = ({id}) => {
    const dispatch = useDispatch()
    const current = useSelector(getChartDuration)
    const isHiddenMobile = CHART_DURATIONS[id].isHiddenMobile
    return (
        <button
            className={`${current === parseInt(id) ? `is-selected` : ``} ${isHiddenMobile ? `hide-portrait` : ``}`}
            onClick={()=>dispatch(setChartDuration(parseInt(id)))}
        >
            {CHART_DURATIONS[id].shortname}
        </button>
    )
}