import './Plans.scss'
import {useQuery} from "@tanstack/react-query";
import {getPlansApi} from "../../api/statusApi";
import {SEC} from "../../util/time-and-date";
import dayjs from "dayjs";
import {numberFormat} from "../../util/numbers";
import {useDispatch} from "react-redux";
import {TRANSACTION_TYPES} from "../../../../back-end/constants/transactions.js";
import {
    setAssetId,
    setCost,
    setIsCompanyTransaction,
    setTransactionDate,
    setType
} from "../../ducks/add-transaction-duck.js";
import {useNavigation} from "../util/navigation/navigation.js";
import {APP_ROUTES} from "../../App.jsx";
import SwapHorizontalHiddenIcon from "mdi-react/SwapHorizontalHiddenIcon.js";
import InternalLink from "../util/navigation/InternalLink.jsx";
import ArrowRightIcon from "mdi-react/ArrowRightIcon.js";

export default () => {

    const {data = {}} = useQuery({
        queryKey: ['plans-api'],
        queryFn: getPlansApi,
        refetchInterval: 10 * SEC,
    })

    const {missingTransactions = []} = data

    return missingTransactions.length > 0 && (
        <div className='plans'>
            <h3 className='flex-vertical-center'>
                <span>
                    Missing transactions
                </span>
                <SwapHorizontalHiddenIcon/>
            </h3>
            <ul>
                {missingTransactions.map(tx => <MissingTransaction key={`${tx.asset_id}-${tx.date}`} {...tx} />)}
            </ul>
            <InternalLink
                to={APP_ROUTES.transactionsAddBulk}
                className='bulk-import-link'
                icon={<ArrowRightIcon/>}
                isIconRight={true}
            >
                Go to bulk import
            </InternalLink>
        </div>
    )
}

const MissingTransaction = ({date, asset_id, asset_name, amount, is_company}) => {
    const dispatch = useDispatch()
    const {navigateTo} = useNavigation()
    const dateFormatted = dayjs(date).format('YYYY-MM-DD')
    const onClick = () => {
        dispatch(setType(TRANSACTION_TYPES.BUY))
        dispatch(setAssetId(asset_id))
        dispatch(setTransactionDate(dateFormatted))
        dispatch(setCost(amount))
        dispatch(setIsCompanyTransaction(!!is_company))
        navigateTo(`${APP_ROUTES.transactionsAdd}?skipTo=2`)
    }
    return (
        <li>
            <button onClick={onClick}>
                <div className='date'>
                    {dateFormatted}
                </div>
                <div className='amount'>
                    <span className='prefix'>kr </span>
                    {numberFormat(amount, 0)}
                </div>
                <div className='asset-name'>
                    {asset_name}
                </div>
            </button>
        </li>
    )
}