import {DECIMAL_FORMAT, numberFormat, sumProps} from "../../../util/numbers.js";
import {orderBy} from "lodash";
import {useEffect, useState} from "react";

import './CompositionBar.scss'
import {getStorage, setStorage} from "../../../api/_token.js";
import {SEC} from "../../../util/time-and-date.js";
import PauseIcon from "mdi-react/PauseIcon.js";
import PlayIcon from "mdi-react/PlayIcon.js";
import CompositionBarItem from "./CompositionBarItem.jsx";
import Counter from "../Counter.jsx";

export default ({
                    items = [],
                    props = [],
                    label = 'generic',
                    objectOnClick = id => console.log(`Object ${id} clicked`),
                    showSelectionControls = true,
                    className = '',
                    carouselSpeed = 5 * SEC,
                }) => {

    const [focus, setFocus] = useState({})
    const [isInfoVisible, setIsInfoVisible] = useState(false)
    const [prop, setProp] = useState(getStorage(`${label}-composition-prop`, props[0].id))
    const [carouselIsPaused, setCarouselIsPaused] = useState(getStorage(`${label}-composition-carousel-is-paused`, false))
    const [carouselIdx, setCarouselIdx] = useState(0)
    const [isMouseOver, setIsMouseOver] = useState(false)

    const activeProp = props.find(item => item.id === prop)
    const {
        isColored,
        filter: propFilter = () => true,
        includeRelativeValue,
        absoluteValueDecimals,
        absoluteValueSuffix,
        absoluteValuePrefix,
    } = activeProp

    useEffect(() => {
        const timer = setTimeout(() => {
            if (!carouselIsPaused && !isMouseOver) {
                const idx = carouselIdx >= props.length - 1 ? 0 : carouselIdx + 1
                setCarouselIdx(idx)
                setProp(props[idx].id)
            }
        }, carouselSpeed)
        return () => clearTimeout(timer)
    }, [carouselIdx, carouselIsPaused, props, isMouseOver, carouselSpeed])

    const changeProp = val => {
        setProp(val)
        setCarouselIsPaused(true)
        setStorage(`${label}-composition-prop`, val)
    }

    const toggleCarousel = () => {
        const isPaused = !carouselIsPaused
        setCarouselIsPaused(isPaused)
        setStorage(`${label}-composition-carousel-is-paused`, isPaused)
    }

    const onMouseOver = () => {
        setIsMouseOver(true)
    }

    const onMouseOut = () => {
        setIsMouseOver(false)
        setIsInfoVisible(false)
    }

    const relevantItems = orderBy(items.filter(propFilter).map(item => ({
        ...item,
        abs: Math.abs(item[prop])
    })), ['abs'], ['desc'])

    const {[prop]: totalValue} = sumProps(relevantItems, [prop], true)

    const {items: appendedItems} = relevantItems.reduce((acc, cur) => {
        const {items, percentAccumulated} = acc
        const percent = Math.abs(cur[prop] / totalValue * 100)
        return {
            items: [
                ...items,
                {
                    ...cur,
                    percent,
                    percentAccumulated,
                }
            ],
            percentAccumulated: percentAccumulated + percent
        }
    }, {
        items: [],
        percentAccumulated: 0,
    })

    const {
        label: focusLabel,
        value,
        percent,
        percentOffset,
    } = focus

    return appendedItems.length > 0 && (
        <div
            className={`composition-bar ${className}`}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
        >
            <div className='composition-bar-items children-slide-down'>
                {appendedItems.map(item =>
                    <CompositionBarItem
                        {...item}
                        key={item.id}
                        totalValue={totalValue}
                        setFocus={setFocus}
                        setIsInfoVisible={setIsInfoVisible}
                        isColored={isColored}
                        includeRelativeValue={includeRelativeValue}
                        objectOnClick={objectOnClick}
                        absoluteValueDecimals={absoluteValueDecimals}
                        absoluteValueSuffix={absoluteValueSuffix}
                        absoluteValuePrefix={absoluteValuePrefix}
                        value={item[prop]}
                    />
                )}
            </div>
            {showSelectionControls &&
            <div className='selector flex-vertical-center'>
                {props.map(({id, label}) =>
                    <button
                        key={id}
                        onClick={() => changeProp(id)}
                        className={prop === id ? 'is-selected' : ''}
                    >
                        {label}
                    </button>
                )}
                <button
                    onClick={toggleCarousel}
                    className='playback'
                >
                    {!carouselIsPaused ? <PauseIcon/> : <PlayIcon/>}
                </button>
            </div>
            }
            <div
                className={`information-card ${isInfoVisible ? 'is-visible' : 'is-hidden'}`}
                style={{'--percentOffset': `${Math.min(percentOffset, 85)}%`}}
            >
                <div className='content'>
                    <div className='item-label'>
                        {focusLabel}
                    </div>
                    <div className='numbers'>
                        {includeRelativeValue &&
                        <div className='percentage'>
                            {numberFormat(percent, DECIMAL_FORMAT.FLEX_PERCENTAGE)}<span className='suffix'>%</span>
                        </div>
                        }
                        <div className='amount'>
                            <Counter
                                decimals={absoluteValueDecimals}
                                amount={value}
                                prefix={absoluteValuePrefix}
                                suffix={absoluteValueSuffix}
                                speed={0}
                                noColor={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}