import {createSelector, createSlice} from "@reduxjs/toolkit";
import {getStorage, setStorage} from "../api/_token.js";

export const NAME = 'chart';

export const CHART_DURATIONS = {
    7: {
        name: "Week",
        shortname: "1w",
        isHiddenMobile: true,
    },
    31: {
        name: "Month",
        shortname: "1m",
        isHiddenMobile: false,
    },
    92: {
        name: "3 months",
        shortname: "3m",
        isHiddenMobile: false,
    },
    183: {
        name: "6 months",
        shortname: "6m",
        isHiddenMobile: false,
    },
    365: {
        name: "Year",
        shortname: "1y",
        isHiddenMobile: false,
    },
    1096: {
        name: "Year",
        shortname: "3y",
        isHiddenMobile: true,
    },
    1826: {
        name: "5 years",
        shortname: "5y",
        isHiddenMobile: true,
    },
    3652: {
        name: "All",
        shortname: "All",
        isHiddenMobile: false,
    },
}

export const CHART_FOCUSES = {
    gain: {
        name: "return",
        shortname: "gain",
    },
    value: {
        name: "value",
        shortname: "Val",
    },
}

const initialState = {
    chartDuration: getStorage('chart-duration', 92),
    chartFocus: getStorage('chart-focus', 'gain'),
    chartHoverDate: undefined,
    chartHoverValues: {
        focusValue: 0,
        value: 0,
        valuePrev: 0,
        gain: 0,
        gainPrev: 0,
        updateKey: "",
    },
    diffTrackerValues: {
        date: undefined,
        gain: 0,
        return: 0,
    },
    isDiffTrackerVisible: false,
    showLatestValue: getStorage('show-latest', true),
    isAthGolden: getStorage('is-ath-golden', true),
    isZeroBaseValue: getStorage('is-zero-base', false),
}

const {actions, reducer} = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setChartDuration: (state, {payload}) => {
            setStorage('chart-duration', payload)
            state.chartDuration = payload
        },
        setChartFocus: (state, {payload}) => {
            setStorage('chart-focus', payload)
            state.chartFocus = payload
        },
        setChartHoverValues: (state, { payload }) => {
            const { date } = payload
            state.chartHoverDate = date
            state.chartHoverValues = {
                ...payload
            }
        },
        setDiffTrackerValues: (state, { payload }) => {
            state.diffTrackerValues = {
                ...payload
            }
            state.isDiffTrackerVisible = true
        },
        setIsDiffTrackerVisible: (state, { payload }) => {
            state.isDiffTrackerVisible = payload
        },
        setChartHoverValueChange: (state, {payload}) => {
            state.chartHoverValueChange = payload
        },
        setShowLatestValue: (state, {payload}) => {
            setStorage('show-latest', payload)
            state.showLatestValue = payload
        },
        setIsAthGolden: (state, {payload}) => {
            setStorage('is-ath-golden', payload)
            state.isAthGolden = payload
        },
        setIsZeroBaseValue: (state, {payload}) => {
            setStorage('is-zero-base', payload)
            state.isZeroBaseValue = payload
        },
        reset: () => ({
            ...initialState
        }),
    }
})

export const {
    setChartDuration,
    setChartFocus,
    setChartHoverValues,
    setChartHoverValueChange,
    setDiffTrackerValues,
    setIsDiffTrackerVisible,
    setShowLatestValue,
    setIsAthGolden,
    setIsZeroBaseValue,
} = actions

const getState = state => state[NAME]

export const getChartDuration = state => getState(state).chartDuration
export const getChartFocus = state => getState(state).chartFocus
export const getChartHoverDate = state => getState(state).chartHoverDate
export const getChartHoverValues = state => getState(state).chartHoverValues
export const getDiffTrackerValues = state => getState(state).diffTrackerValues
export const getIsDiffTrackerVisible = state => getState(state).isDiffTrackerVisible
export const getShowLatestValue = state => getState(state).showLatestValue
export const getIsAthGolden = state => getState(state).isAthGolden
export const getIsZeroBaseValue = state => getState(state).isZeroBaseValue

export const getUpdateKey = createSelector([getChartHoverValues], values => values.updateKey)

export default reducer