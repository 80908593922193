import React from 'react';

import './LoaderCircle.scss';

const LoaderCircle = ({className, ...restOfProps}) => {
    return (
        <div className={`loader loader-circle ${className}`} {...restOfProps} />
    )
};

export default LoaderCircle;